import { Box, Typography } from "@mui/material";
import RequestforOrderApprovalTable from "../../components/RequestforOrderApprovalTable";
import { themeColors } from "../../constant/ThemeColor";
import PreviousOrderRequestsTable from "../../components/PreviousOrderRequestsTable";
import { useState, useEffect } from "react";
import {
  getApproveDeclinedList,
  getTransferList,
} from "../../ApisActions/workflowApisActions";

const Workflows = () => {
  const [trasferListData, setTransferListData] = useState(null);
  const [approveDeclineData, setApproveDeclineData] = useState(null);

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const response = await getTransferList({ setLoading });
    setTransferListData(response);
  };

  const fetchApprovedDeclinedData = async () => {
    const response = await getApproveDeclinedList({ setLoading });
    setApproveDeclineData(response);
  };

  useEffect(() => {
    fetchApprovedDeclinedData();
    fetchData();
  }, []);

  if (loading) {
    return (
      <div style={{ color: "#000" }} className="pb-3 pl-3 pt-3">
        Loading...
      </div>
    );
  }

  return (
    <Box component={"div"} pb={3} pl={3} pr={"106px"}>
      <Typography
        variant="h4"
        color={themeColors.textHeading}
        sx={{
          color: "#0C151B",
          fontWeight: 500,
          paddingTop: "41px",
          paddingBottom: "16px",
        }}
      >
        Workflows
      </Typography>

      <RequestforOrderApprovalTable
        trasferListData={trasferListData}
        fetchData={fetchData}
        fetchApprovedDeclinedData={fetchApprovedDeclinedData}
      />

      <PreviousOrderRequestsTable approveDeclineData={approveDeclineData} />
    </Box>
  );
};
export default Workflows;
