import { Box } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { themeColors } from "../constant/ThemeColor";

const data = [
  {
    name: "Local",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Other Locations",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
];

const getIntroOfPage = (label) => {
  if (label === "Page A") {
    return "Page A is about men's clothing";
  }
  if (label === "Page B") {
    return "Page B is about women's dress";
  }
  if (label === "Page C") {
    return "Page C is about women's bag";
  }
  if (label === "Page D") {
    return "Page D is about household goods";
  }
  if (label === "Page E") {
    return "Page E is about food";
  }
  if (label === "Page F") {
    return "Page F is about baby food";
  }
  return "";
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <Box component={"label"} className="label">{`${label} : ${payload[0].value}`}</Box>
        <Box component={"label"} className="intro">{getIntroOfPage(label)}</Box>
        <Box component={"p"} color={themeColors.primary} className="desc">Anything you want can be displayed here.</Box>
      </div>
    );
  }

  return null;
};

const PurchaseChartTow = () => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={500}
        height={200}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <CartesianGrid
          style={{ strokeWidth: "0.5px" }}
          stroke="#D2D2D2"
          horizontal={true}
          vertical={false}
        />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Bar dataKey="pv" barSize={60} fill="#D7F62F" />
        {/* <Bar dataKey="pv" barSize={30} fill={(entry) => entry.color} /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};
export default PurchaseChartTow;
