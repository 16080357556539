import axios from "axios";
import URL from "../../Network/endpoint";

export const getAllUsersList = async ({ setLoading = () => {} }) => {
  setLoading(true);
  try {
    const response = await axios.get(URL.MEMBERS_LIST);

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const addMemberBudget = async ({ setLoading = () => {} }, amt, id) => {
  setLoading(true);
  console.log("id", id);
  const data = {
    budget: amt,
  };
  console.log("data", data);

  try {
    const response = await axios.post(`${URL.ADD_BUDGET}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const changeMembersStatus = async (
  { setLoading = () => {} },
  status,
  id
) => {
  setLoading(true);
  const data = {
    purchasing_status: status,
  };
  try {
    const response = await axios.post(`${URL.CHANGE_STATUS}/${id}`, data);
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const exportData = async ({ setLoading = () => {} }, values) => {
  setLoading(true);
  console.log("values", values);

  try {
    const response = await axios.post(URL.EXPORT_DATA, values);
    console.log("response", response);
    return response
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};
