import "../../assets/css/purchase.css";
import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { themeColors } from "../../constant/ThemeColor";
import DataTable from "../../components/dataTable";
import PurchaseChartOne from "../../components/purchaseChartOne";
import PurchaseChartTow from "../../components/purshaseChartTwo";
import axios from "axios";
import URL from "../../Network/endpoint";

const Purchases = () => {
  const [orderListData, setOrderListrData] = useState(null);

  const getOrderList = async () => {
    try {
      const response = await axios.get(URL.ORDER_LIST);
      console.log("response", response);
      setOrderListrData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getOrderList();
  }, []);

  return (
    <Box component={"div"} pb={3} pl={3} pr={"106px"}>
      <Typography
        variant="h4"
        color={themeColors.textHeading}
        sx={{ paddingTop: "16px" }}
      >
        Purchases
      </Typography>
      <section className="Account-Actives-purshases">
        <Typography
          variant="h5"
          color={themeColors.textHeading}
          sx={{ paddingBottom: "16px" }}
        >
          Purchase Overview
        </Typography>
        {console.log("orderListData", orderListData)}
        <DataTable orderListData={orderListData} />
        <Box>
          <Typography
            variant="h5"
            color={themeColors.textHeading}
            sx={{ paddingTop: "24px" }}
          >
            Spending Activities
          </Typography>
          <div className="grid-container">
            <Box className="grid-item">
              <Typography variant="h6" color={themeColors.gray}>
                Order Status Totals
              </Typography>
              <Typography
                variant="h2"
                color={themeColors.textHeading}
                pt={2}
                sx={{ paddingBottom: "73px" }}
              >
                $20,500{" "}
              </Typography>
            </Box>
            <Box className="grid-item">
              <Typography variant="h6" color={themeColors.gray}>
                Socio-Economic Goals
              </Typography>
              <Typography
                variant="h2"
                color={themeColors.textHeading}
                pt={2}
                sx={{ paddingBottom: "16px" }}
              >
                $2,000
              </Typography>
            </Box>
            <Box className="grid-item">
              <Typography variant="h6" color={themeColors.gray}>
                Spend By Category
              </Typography>
              <Typography
                variant="h2"
                color={themeColors.textHeading}
                pt={2}
                sx={{ paddingBottom: "16px" }}
              >
                $3,100
              </Typography>
            </Box>
          </div>
        </Box>
      </section>
      <section className="charts-config">
        <div className="row">
          <div className="col-8">
            <Typography
              variant="h5"
              color={themeColors.textHeading}
              sx={{ paddingBottom: "16px" }}
            >
              Socio-Economic Overview
            </Typography>
            <div className="chat-bg">
              <Typography
                variant="h6"
                color={themeColors.gray}
                sx={{ paddingLeft: "17px", paddingBottom: "15px" }}
              >
                Spend History
              </Typography>
              <PurchaseChartOne />
            </div>
          </div>
          <div className="col-4">
            <Typography
              variant="h5"
              color={themeColors.textHeading}
              sx={{ paddingBottom: "16px" }}
            >
              Geographical Overview
            </Typography>
            <div className="chat-bg">
              <Typography
                variant="h6"
                color={themeColors.gray}
                sx={{ paddingLeft: "17px", paddingBottom: "15px" }}
              >
                Spend History
              </Typography>
              <PurchaseChartTow />
            </div>
          </div>
        </div>
      </section>
    </Box>
  );
};
export default Purchases;
