export const baseUlr = "https://santamonica.projectanddemoserver.com/";

const URL = {
  ORDER_LIST: baseUlr + "orders/list",
  TRANSFER_LIST: baseUlr + "transfer-cart/list/1",
  APPROVED_API: baseUlr + "transfer-cart/approve",
  DECLINE_API: baseUlr + "transfer-cart/decline",
  APPROVED_DECLINED_LIST: baseUlr + "transfer-cart/approve-declined-list",
  MEMBERS_LIST: baseUlr + "users/list",
  ADD_BUDGET: baseUlr + "users/order-budget",
  CHANGE_STATUS: baseUlr + "users/purchase-status",
  ADD_MEMBER: baseUlr + "users/create-cardholder-user",
  EXPORT_DATA: baseUlr + "download-csv",
  SETTING_API: baseUlr + "users/settings",
  CREATESHOPIFYCHECKOUT_API: baseUlr + "transfer-cart/create-checkout",
};
export default URL;
