import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { Link } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { themeColors } from "../constant/ThemeColor";
import "../assets/css/purchase.css";
import {
  approvedRequest,
  declineRequest,
} from "../ApisActions/workflowApisActions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6F7C84",
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    background: "#fff",
    color: "#0C151B",
    borderBottom: "1px solid #EFEFEF",
    padding: "20px",
    textAlign: "-webkit-center",
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
    color: "#000",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& .MuiIconButton-root": {
    marginLeft: theme.spacing(1),
  },
}));

const RequestforOrderApprovalTable = ({
  trasferListData,
  fetchData,
  fetchApprovedDeclinedData,
}) => {
  return (
    <Box component={"div"}>
      <Typography
        variant="h5"
        color={themeColors.textHeading}
        sx={{
          color: "#0C151B",
          fontWeight: 500,
          paddingBottom: "16px",
        }}
      >
        Request for Order Approval
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ background: "#6F7C84", textAlign: "center" }}>
            <TableRow>
              <StyledTableCell>Request</StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">User</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Cart</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Price</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Action</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trasferListData?.data?.map((element, index) => {
              return (
                <StyledTableRow key={element.id}>
                  <StyledTableCell component="th" scope="row">
                    {element.cart_id}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box component={"span"} sx={{ display: "block" }}>
                      {element.transfer_by_user_name}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link
                      to={`https://santamonica-commerce-glass-demo.myshopify.com/pages/workflow-preview-cart?transferCartId=${element.id}`}
                      target="_blank"
                    >
                      click here to preview cart
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    ${element.total}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box component={"div"} className="btn-link-div">
                      <Box component={"div"} className="btn-div">
                        <button
                          className="btn btn-shop-for-workflow"
                          onClick={async () => {
                            await approvedRequest(element.id);
                            fetchData();
                            fetchApprovedDeclinedData();
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              color: "#000",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            Approve
                          </Typography>
                        </button>
                      </Box>
                      <Box component={"div"} className="btn-div">
                        <button className="btn-deny">
                          <Typography
                            variant="h4"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            <button
                              className="deny-btn"
                              onClick={async () => {
                                await declineRequest(element.id);
                                fetchData();
                                fetchApprovedDeclinedData();
                              }}
                            >
                              Deny
                            </button>
                          </Typography>
                        </button>
                      </Box>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default RequestforOrderApprovalTable;
