import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Chip, Typography } from "@mui/material";
import { themeColors } from "../constant/ThemeColor";
import "../assets/css/dashboard.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const DataTable = ({ orderListData }) => {
  console.log("orderListData", orderListData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  console.log("orderListData", orderListData);

  const columns = [
    { id: "orderId", label: "Order ID" },
    { id: "status", label: "Status" },
    { id: "customer", label: "Customer" },
    { id: "date", label: "Date" },
    { id: "total", label: "Total" },
    { id: "actions", label: "Actions" },
  ];

  const classes = useStyles();

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ background: "#ffffff", color: "#000" }}
        className="main-table-contaier"
      >
        <Box component={"div"} className="table-heading">
          <Box>
            <Typography variant="h6" color={themeColors.gray}>
              My Purchases
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" fontSize={12} fontWeight={400}>
              Filter By:
            </Typography>
          </Box>
        </Box>
        <Table className={classes.table} aria-label="simple table">
          <TableHead sx={{}}>
            <TableRow>
              {columns.map((column) => (
                <TableCell sx={{ color: "#6D7278" }} key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {orderListData?.data?.map((element) => (
              <TableRow
                sx={{
                  "*": {
                    color: "#000000",
                  },
                }}
                key={element.order_id}
              >
                <TableCell component="th" scope="row">
                  {element.order_id}
                </TableCell>
                <TableCell>
                  <Chip
                    label={element.status}
                    sx={{
                      backgroundColor:
                        element.status?.toLowerCase() === "delivered"
                          ? themeColors.highLight
                          : element.status?.toLowerCase() === "on track"
                          ? themeColors.onTrackColor
                          : themeColors?.pending,
                      color: themeColors.primary,
                    }}
                  />
                </TableCell>
                <TableCell>{element.customer}</TableCell>
                <TableCell>{element.date}</TableCell>
                <TableCell>{element.total}</TableCell>
                <TableCell>{element.actions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default DataTable;
