import { Box, Typography } from "@mui/material";
import React from "react";
import { themeColors } from "../../constant/ThemeColor";
import QuotesDataTable from "../../components/QuotesDataTable";
import "../../assets/css/quotes.css";

const Quotes = () => {
  return (
    <Box className="my-quotes" component={"div"} pb={3} pl={3} pr={"106px"}>
      <Typography
        variant="h4"
        color={themeColors.textHeading}
        sx={{ paddingTop: "16px"}}
      >
        Quotes
      </Typography>
      <section className="Account-Actives-purshases">
        <Typography
          variant="h5"
          color={themeColors.textHeading}
          sx={{ paddingBottom: "16px" }}
        >
          Quotes Overview
        </Typography>
        <QuotesDataTable />
      </section>
    </Box>
  );
};

export default Quotes;
