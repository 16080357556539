import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../assets/css/quotes.css";
import { Box, Chip } from "@mui/material";
import { themeColors } from "../constant/ThemeColor"

const QuotesDataTable = () => {
  return (
    <Box component={"div"} className="my-table">
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ background: "#ffffff" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                color: "#6d7278",
                background: "#fff",
                paddingTop: "15px",
                paddingBottom: "16px",
  
              }}
            >
              <TableRow>
                <TableCell
                  align=""
                  colSpan={2}
                  sx={{
                    variant:"h6",
                    background: "#fff",
                    color: "gray",
                    borderBottom: "none",
                  }}
                >
                  My Quotes
                </TableCell>
                <TableCell
                  align=""
                  colSpan={3}
                  sx={{
                    padding: "18px 88px 0px 0px",
                    background: "#fff",
                    color: "gray",
                    borderBottom: "none",
                    textAlign: "end",
                  }}
                >
                  Filter By:
                </TableCell>
              </TableRow>
              <TableRow sx={{ background: "#fff", color: "#000" }}>
                <TableCell>Quotes ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Delivered To</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="table-row">
                <TableCell component="th" scope="row">
                  001
                </TableCell>

                <TableCell>
                  <Chip
                    label="Sent"
                    sx={{
                      width: "64px",
                      height: "19px",
                      borderRadius: "9.5px",
                      backgroundColor: themeColors.highLight,
                      color: themeColors.primary,
                      fontWeight: "500",
                    }}
                  />
                </TableCell>
                <TableCell>Nick Hu</TableCell>
                <TableCell>October 2, 2023</TableCell>
                <TableCell>n.hu@commerce.glass</TableCell>
              </TableRow>
              <TableRow className="table-row">
                <TableCell component="th" scope="row">
                  002
                </TableCell>
                <TableCell>
                  <Chip
                    label="Sent"
                    sx={{
                      width: "64px",
                      height: "19px",
                      borderRadius: "9.5px",
                      backgroundColor: themeColors.highLight,
                      color: themeColors.primary,
                      fontWeight: "500",
                    }}
                  />
                </TableCell>
                <TableCell>Nick Hu</TableCell>
                <TableCell>October 2, 2023</TableCell>
                <TableCell>n.hu@commerce.glass</TableCell>
              </TableRow>
              <TableRow className="table-row">
                <TableCell component="th" scope="row">
                  003
                </TableCell>
                <TableCell>
                  <Chip
                    label="Sent"
                    sx={{
                      width: "64px",
                      height: "19px",
                      borderRadius: "9.5px",
                      backgroundColor: themeColors.highLight,
                      color: themeColors.primary,
                      fontWeight: "500",
                    }}
                  />
                </TableCell>
                <TableCell>Nick Hu</TableCell>
                <TableCell>October 2, 2023</TableCell>
                <TableCell>n.hu@commerce.glass</TableCell>
              </TableRow>
              <TableRow className="table-row">
                <TableCell component="th" scope="row">
                  004
                </TableCell>
                <TableCell>
                  <Chip
                    label="Sent"
                    sx={{
                      width: "64px",
                      height: "19px",
                      backgroundColor: themeColors.highLight,
                      color: themeColors.primary,
                      borderRadius: "9.5px",
                      fontWeight: "500",
                    }}
                  />
                </TableCell>
                <TableCell>Nick Hu</TableCell>
                <TableCell>October 2, 2023</TableCell>
                <TableCell>n.hu@commerce.glass</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
export default QuotesDataTable;
