import { Box, Typography } from "@mui/material";
import { themeColors } from "../constant/ThemeColor";
import { useLocation } from "react-router-dom";

const TopBar = () => {
  const location = useLocation();
  const hideTopBar = location.state && location.state.hideTopBar;

  console.log("hideTopBar", hideTopBar)

  if (hideTopBar) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent={"space-between"}
      alignItems={"flex-end"}
      pt={3}
      // pb={3}
      pl={3}
      pr={"106px"}
    >
      <Box>
        <Typography
          fontSize={14}
          fontWeight={700}
          color={themeColors.textHeading}
        >
          Hi Nakul!
        </Typography>
        <Typography variant="h6" color={themeColors.gray}>
          Here is your government dashboard.
        </Typography>
      </Box>

      <Box display="flex">
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}

        <Typography variant="h6" color={themeColors.textHeading}>
          Sept 23, 2023 - Oct 23, 2023
        </Typography>
      </Box>
    </Box>
  );
};

export default TopBar;
