import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Chip, Typography } from "@mui/material";
import { themeColors } from "../constant/ThemeColor";
import CircularProgress from "@mui/material/CircularProgress";
import "../assets/css/purchase.css";

import URL from "../Network/endpoint";
import axios from "axios";
import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6F7C84",
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    background: "#fff",
    color: "#0C151B",
    borderBottom: "1px solid #EFEFEF",
    padding: "30px",
    textAlign: "-webkit-center",
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
    color: "#000",
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& .MuiIconButton-root": {
    marginLeft: theme.spacing(1),
  },
}));

const PreviousOrderRequestsTable = ({ approveDeclineData }) => {
  const [loadingStates, setLoadingStates] = useState({});
  const handleCheckout = async (id) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: true,
    }));

    try {
      const response = await axios.post(URL.CREATESHOPIFYCHECKOUT_API, {
        cart_id: id,
        shopName: "santamonica-commerce-glass-demo.myshopify.com",
      });
      console.log("response >>>", response);
      var webCheckoutUrl = response.data.data.checkout.web_url;
      console.log(webCheckoutUrl);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
    }
  };

  return (
    <Box component={"div"}>
      <Typography
        variant="h5"
        color={themeColors.textHeading}
        sx={{
          color: "#0C151B",
          fontWeight: 500,
          paddingTop: "41px",
          paddingBottom: "16px",
        }}
      >
        Previous Order Requests
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ background: "#6F7C84", textAlign: "center" }}>
            <TableRow>
              <StyledTableCell>Request</StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">User</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Cart</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Price</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="">Action</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {approveDeclineData?.data?.map((element, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {element?.id}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Box component={"span"} sx={{ display: "block" }}>
                    {element?.transfer_by_user_name}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {element.status === "approved" && (
                    <Chip
                      label={
                        loadingStates[element.cart_id] ? (
                          <CircularProgress size={22} />
                        ) : (
                          "Checkout"
                        )
                      }
                      color="primary"
                      variant="outlined"
                      onClick={() => handleCheckout(element.cart_id)}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  $ {element.total}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Box component={"div"} className="btn-link-div">
                    <Box component={"div"} className="btn-link-div">
                      {element.status === "declined" ? (
                        <button className="btn workflow-Approval-pending">
                          <Typography
                            variant="h4"
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            {element?.status}
                          </Typography>
                        </button>
                      ) : (
                        <button className="btn workflow-Approval">
                          <Typography
                            variant="h4"
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            {element?.status}
                          </Typography>
                        </button>
                      )}
                    </Box>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default PreviousOrderRequestsTable;
