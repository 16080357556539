import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  Modal,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { themeColors } from "../../constant/ThemeColor";
import "../../assets/css/purchase.css";
import { useState } from "react";
import { useEffect } from "react";
import {
  addMemberBudget,
  changeMembersStatus,
  exportData,
  getAllUsersList,
} from "../../ApisActions/membersApisActions";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6F7C84",
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    background: "#fff",
    color: "#0C151B",
    borderBottom: "1px solid #EFEFEF",
    padding: "25px",
    textAlign: "center",
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
    color: "#000",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& .MuiIconButton-root": {
    marginLeft: theme.spacing(1),
  },
}));

const Members = () => {
  const navigate = useNavigate();
  const [budget, setBudget] = useState();
  const [membersListData, setMembersListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState({});

  const [open, setOpen] = useState({
    show: false,
    id: null,
  });

  const handlePopoverOpen = (event, id) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [id]: event.currentTarget,
    }));
  };

  const handlePopoverClose = () => {
    setPopoverOpen({});
  };

  const handleBudgetChange = (event) => {
    let value = parseInt(event.target.value, 10);
    value = isNaN(value) ? 100 : Math.max(value, 100);
    setBudget(value);
  };

  const handleOpen = (id) =>
    setOpen({
      show: true,
      id: id,
    });

  const handleClose = () =>
    setOpen({
      show: false,
      id: null,
    });

  useEffect(() => {
    fetchMembersListData();
  }, []);

  const fetchMembersListData = async () => {
    const response = await getAllUsersList({ setLoading });
    setMembersListData(response);
  };

  const downloadData = async (values) => {
    try {
      const members = values.map((customer) => {
        const {
          first_name,
          last_name,
          orders_count,
          createdAt,
          purchasing_status,
        } = customer;

        return {
          name: `${first_name} ${last_name}`,
          total_purchases: orders_count,
          registered_date: createdAt,
          last_login: "Sept 30, 2023",
          status: purchasing_status,
        };
      });
      const formattedData = { members };
      const response = await exportData({ setLoading }, formattedData);
      const csvData = response.data;
      const blob = new Blob([csvData], { type: "text/csv" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "exported_data.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const addBudget = async (amt, id) => {
    const response = await addMemberBudget({ setLoading }, amt, id);
    handleClose();
  };

  const changeStatus = async (status, id) => {
    await changeMembersStatus({ setLoading }, status, id);
    handlePopoverClose();
    fetchMembersListData();
  };

  if (loading) {
    return (
      <div style={{ color: "#000" }} className="pb-3 pl-3 pt-3">
        Loading...
      </div>
    );
  }

  return (
    <Box component={"div"} pb={3} pl={3} pr={"106px"}>
      <Typography
        variant="h4"
        color={themeColors.textHeading}
        sx={{
          padding: "30px 0px",
          color: "#0C151B",
        }}
      >
        Members
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ background: "#6F7C84", textAlign: "center" }}>
            <TableRow>
              <StyledTableCell align="center">
                <Typography variant="h5">Name</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="h5">Total Purchases</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="h5">Registered Date</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="h5">Last Log In</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="h5">Status</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="h5">Action</Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Modal
              open={open.show}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: themeColors.backgroundColor,
                  border: `2px solid ${themeColors.highLight}`,
                  borderRadius: "5px",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography
                  color={themeColors.primary}
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Add Budget
                </Typography>
                <ThemeProvider theme={theme}>
                  <TextField
                    value={budget}
                    InputProps={{
                      inputProps: { min: 100 },
                      onInput: handleBudgetChange,
                    }}
                    onChange={(e) => {
                      setBudget(e.target.value);
                    }}
                    size="small"
                    margin="normal"
                    type="number"
                    fullWidth
                    label="Budget"
                    error={budget < 100}
                    helperText={budget < 100 && "Min Value Should be 100"}
                  />
                </ThemeProvider>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: themeColors.highLight,
                    backgroundColor: themeColors.highLight,
                  }}
                  onClick={() => {
                    addBudget(budget, open.id);
                  }}
                >
                  Add
                </Button>
              </Box>
            </Modal>
            {membersListData.data?.map((element) => {
              return (
                <StyledTableRow key={element.id}>
                  <StyledTableCell component="th" scope="row">
                    {element.first_name} {element.last_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box component={"span"} sx={{ display: "block" }}>
                      $4,300
                    </Box>
                    <Box
                      onClick={() => {
                        handleOpen(element.id);
                      }}
                      component={"span"}
                      className="add-budget-anchor"
                      sx={{
                        cursor: "pointer",
                        paddingTop: "5px",
                        display: "block",
                        color: "#A1A1A1",
                        textDecoration: "underline",
                      }}
                    >
                      Add Budget
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    March 16, 2020
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Sept 30, 2023
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {element.purchasing_status
                      ? element.purchasing_status
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton
                      onClick={(event) => handlePopoverOpen(event, element.id)}
                      aria-label="settings"
                    >
                      <SettingsIcon
                        style={{ color: "#000", fontSize: "25px" }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(popoverOpen[element.id])}
                      anchorEl={popoverOpen[element.id]}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Paper
                        sx={{
                          backgroundColor: themeColors.backgroundColor,
                          padding: theme.spacing(1),
                          display: "flex",
                          flexDirection: "column",
                          gap: theme.spacing(1),
                        }}
                      >
                        <Button
                          onClick={() => {
                            changeStatus("Active", element.id);
                          }}
                          color="success"
                          variant="contained"
                        >
                          Active
                        </Button>
                        <Button
                          onClick={() => {
                            changeStatus("InActive", element.id);
                          }}
                          color="warning"
                          variant="contained"
                        >
                          Inactive
                        </Button>
                        <Button
                          onClick={() => {
                            changeStatus("StopPurchases", element.id);
                          }}
                          sx={{
                            borderColor: themeColors.highLight,
                            backgroundColor: themeColors.highLight,
                            color: themeColors.primary,
                          }}
                          variant="contained"
                        >
                          Stop Further Purchases
                        </Button>
                      </Paper>
                    </Popover>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box component={"div"} className="create-div-btn">
        <Box>
          <button
            onClick={() => {
              navigate("/createMember");
            }}
            className="create-data-btn"
          >
            <Typography variant="h6">Create New Card Holder</Typography>
          </button>
        </Box>

        {membersListData?.data?.length > 0 && (
          <Box
            onClick={() => {
              downloadData(membersListData?.data);
            }}
            component={"div"}
            className="export-link-div"
          >
            <Typography
              fontSize={"12px"}
              fontWeight={500}
              sx={{
                color: "#000",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Export Data
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Members;
