import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { themeColors } from "../../constant/ThemeColor";
import "../../assets/css/settings.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";
import URL from "../../Network/endpoint";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
  },
});

const CreateMember = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name required"),
    last_name: Yup.string().required("Last Name required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    division: Yup.string().required("Division required"),
    phone: Yup.string()
      .min(8, "Phone number must be at least 8 characters")
      .max(15, "Phone number must not exceed 14 characters")
      .required("Phone number is required"),
    order_budget: Yup.string()
      .min(2, "Budget must be at least 2 characters")
      .max(6, "Budget must not exceed 6 characters")
      .required("Phone number is required"),
    purchasing_status: Yup.string().required("Status required"),
    user_role: Yup.string().required("User Role required"),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      division: "",
      purchasing_status: "",
      user_role: "",
      order_budget: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await settingApi(values);
      resetForm();
    },
  });

  const settingApi = async (values) => {
    setLoading(true);
    try {
      axios.post(`${URL.ADD_MEMBER}`, values);
      navigate(-1);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div style={{ color: "#000" }}>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box component={"div"} className="settings">
        <Typography
          variant="h4"
          color={themeColors.textHeading}
          sx={{
            padding: "30px 24px",
            color: "#0C151B",
          }}
        >
          Add New Card Holder
        </Typography>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              size="small"
              type="text"
              fullWidth
              label="First Name"
              name="first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
            />
            {errors.first_name && touched.first_name && (
              <div className="error-message">{errors.first_name}</div>
            )}
          </ThemeProvider>
        </Box>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              size="small"
              type="text"
              fullWidth
              label="Last Name"
              name="last_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_name}
            />
            {errors.last_name && touched.last_name && (
              <div className="error-message">{errors.last_name}</div>
            )}
          </ThemeProvider>
        </Box>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              size="small"
              type="email"
              fullWidth
              label="Email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {errors.email && touched.email && (
              <div className="error-message">{errors.email}</div>
            )}
          </ThemeProvider>
        </Box>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              size="small"
              type="text"
              fullWidth
              label="Division"
              name="division"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.division}
            />
            {errors.division && touched.division && (
              <div className="error-message">{errors.division}</div>
            )}
          </ThemeProvider>
        </Box>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              size="small"
              type="number"
              fullWidth
              label="Phone Number"
              name="phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
            />
            {errors.phone && touched.phone && (
              <div className="error-message">{errors.phone}</div>
            )}
          </ThemeProvider>
        </Box>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              size="small"
              type="number"
              fullWidth
              label="Budget"
              name="order_budget"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.order_budget}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
            />
            {errors.order_budget && touched.order_budget && (
              <div className="error-message">{errors.order_budget}</div>
            )}
          </ThemeProvider>
        </Box>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <Select
              size="small"
              fullWidth
              value={values.purchasing_status}
              displayEmpty
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("purchasing_status", e.target.value);
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                Select Status
              </MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="InActive">InActive</MenuItem>
              <MenuItem value="StopPurchases">StopPurchases</MenuItem>
            </Select>

            {errors.purchasing_status && values.purchasing_status === "" && (
              <div className="error-message">{errors.purchasing_status}</div>
            )}
          </ThemeProvider>
        </Box>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <Select
              size="small"
              fullWidth
              displayEmpty
              value={values.user_role}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("user_role", e.target.value);
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                Select User Role
              </MenuItem>
              <MenuItem value="Viewer">Viewer</MenuItem>
              <MenuItem value="Purchaser">Purchaser</MenuItem>
              <MenuItem value="Approver">Approver</MenuItem>
            </Select>

            {errors.user_role && values.user_role === "" && (
              <div className="error-message">{errors.user_role}</div>
            )}
          </ThemeProvider>
        </Box>
        {/* <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            padding: "10px 24px",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              required
              type="password"
              fullWidth
              label="Password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            {errors.password && touched.password && (
              <div className="error-message">{errors.password}</div>
            )}
          </ThemeProvider>
        </Box> */}

        <Box component={"div"} sx={{ padding: "10px 24px" }}>
          <button type="submit" className="btn btn-shop">
            <Typography
              variant="h4"
              sx={{ fontSize: "14px", fontWeight: "500", color: "#000" }}
            >
              Submit
            </Typography>
          </button>
        </Box>
      </Box>
    </form>
  );
};

export default CreateMember;
