import "bootstrap/dist/css/bootstrap.min.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useMode } from "./theme/theme";
import TopBar from "./global/TopBar";
import SideBar from "./global/SideBar";
import { Route, Routes, useNavigate } from "react-router-dom";
import Settings from "./pages/Settings/Settings";
import Purchases from "./pages/Purchases/Purchases";
import Quotes from "./pages/Quotes/Quotes";
import Members from "./pages/Members/Members";
import Workflows from "./pages/Workflows/Workflows";
import Dashboard from "./pages/dashboard";
import { useEffect } from "react";
import CreateMember from "./pages/Members/createMember";

function App() {
  const [theme] = useMode();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log("currentPath", currentPath);

    if (currentPath === "/Workflows" || currentPath === "/Members") {
      navigate(currentPath, { state: { hideTopBar: true } });
    }
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <SideBar />
        <main className={"content"}>
          <TopBar />
          <Routes>
            <Route exact={true} path="/" element={<Dashboard />} />
            <Route exact={true} path="/purchases" element={<Purchases />} />
            <Route exact={true} path="/members" element={<Members />} />
            <Route
              exact={true}
              path="/createMember"
              element={<CreateMember />}
            />
            <Route exact={true} path="/workflows" element={<Workflows />} />
            <Route exact={true} path="/quotes" element={<Quotes />} />
            <Route exact={true} path="/settings" element={<Settings />} />
          </Routes>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
