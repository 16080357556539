import axios from "axios";
import URL from "../../Network/endpoint";

export const getTransferList = async ({ setLoading = () => {} }) => {
  setLoading(true);
  try {
    const response = await axios.get(URL.TRANSFER_LIST);
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const getApproveDeclinedList = async ({ setLoading = () => {} }) => {
  setLoading(true);
  try {
    const response = await axios.get(URL.APPROVED_DECLINED_LIST);
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
  }
};

export const approvedRequest = async (id) => {
  try {
    const response = await axios.post(URL.APPROVED_API, {
      id: id,
      approved_declined_by_user_name: "sachin",
      approved_declined_by_user_email: "sachin@gmail.com",
    });
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};



export const declineRequest = async (id) => {
  console.log("declined api called")
  try {
    const response = await axios.post(URL.DECLINE_API, {
      id: id,
      approved_declined_by_user_name: "sujit bhai",
      approved_declined_by_user_email: "sujit@gmail.com",
    });
    console.log("response", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
